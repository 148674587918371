import React, { ReactNode } from "react";
import ctl from "@netlify/classnames-template-literals";
import Text, { TextWeight } from "../Text";

export type TagTheme = "dark" | "light" | "medium";
export type TagVariant =
  | "exclusive"
  | "default"
  | "info"
  | "negative"
  | "warning"
  | "success"
  | "popular"
  | "purple"
  | "therapy"
  | "professionalCoaching";

type TextAlign = "left" | "center" | "right";
type Opacity = "opaque" | "semiOpaque" | "transparent";
export type TextSize = "5xl" | "2xl" | "xl" | "base" | "sm" | "xs";

interface Props {
  children: ReactNode;
  theme?: TagTheme;
  variant?: TagVariant;
  fillWidth?: boolean;
  align?: TextAlign;
  backgroundOpacity?: Opacity;
  size?: TextSize;
  weight?: TextWeight;
  customBackGroundClass?: string;
}

export default function Tag({
  children,
  theme = "light",
  variant = "default",
  fillWidth = false,
  backgroundOpacity = "opaque",
  align = "left",
  size = "sm",
  weight = "regular",
  customBackGroundClass,
}: Props): JSX.Element {
  const className = getClasses(
    theme,
    variant,
    fillWidth,
    align,
    backgroundOpacity,
    customBackGroundClass,
  );

  return (
    <span className={className}>
      <Text variant="body" weight={weight} align={align} size={size}>
        {children}
      </Text>
    </span>
  );
}

export function getClasses(
  theme: TagTheme,
  variant: TagVariant,
  fillWidth: boolean,
  align: TextAlign,
  backgroundOpacity: Opacity,
  customBackGroundClass = "",
): string {
  const baseClasses = `inline-flex items-center px-1.5 py-1 rounded-md ${
    align === "center" ? "justify-center" : ""
  } ${fillWidth ? "w-full" : ""} ${customBackGroundClass}`;

  const backgroundOpacityClasses = {
    opaque: "bg-opacity-100",
    semiOpaque: "bg-opacity-70",
    transparent: "bg-opacity-0",
  };

  const variantClasses = {
    default: {
      dark: ctl(`bg-neutral-700 text-neutral-100`),
      medium: ctl(`bg-neutral-500 text-white`),
      light: ctl(`bg-neutral-100 text-neutral-700`),
    },
    exclusive: {
      dark: ctl(`bg-neutral-900 text-white`),
      medium: ctl(`bg-neutral-500 text-white`),
      light: ctl(`bg-white text-neutral-900`),
    },
    info: {
      dark: ctl(`bg-status-teal-700 text-status-teal-50`),
      medium: ctl(`bg-status-teal-500 text-white`),
      light: ctl(`bg-status-teal-50 text-status-teal-700`),
    },
    negative: {
      dark: ctl(`bg-status-red-700 text-status-red-50`),
      medium: ctl(`bg-status-red-500 text-white`),
      light: ctl(`bg-status-red-50 text-status-red-700`),
    },
    purple: {
      dark: ctl(`bg-purple-600 text-white`),
      medium: ctl(`bg-purple-500 text-white`),
      light: ctl(`bg-purple-50 text-neutral-700`),
    },
    warning: {
      dark: ctl(`bg-status-yellow-700 text-status-yellow-50`),
      medium: ctl(`bg-status-yellow-500 text-white`),
      light: ctl(`bg-status-yellow-50 text-status-yellow-700`),
    },
    success: {
      dark: ctl(`bg-status-green-700 text-status-green-50`),
      medium: ctl(`bg-status-green-500 text-white`),
      light: ctl(`bg-status-green-50 text-status-green-700`),
    },
    // Only one theme for now
    popular: {
      dark: ctl(`bg-status-teal-500 text-white`),
      medium: ctl(`bg-status-teal-500 text-white`),
      light: ctl(`bg-status-teal-500 text-white`),
    },
    therapy: {
      dark: ctl(`bg-accent-purple-400 text-purple-900`),
      medium: ctl(`bg-accent-purple-400 text-purple-900`),
      light: ctl(`bg-accent-purple-400 text-purple-900`),
    },
    professionalCoaching: {
      dark: ctl(`bg-accent-blue-400 text-purple-900`),
      medium: ctl(`bg-accent-blue-400 text-purple-900`),
      light: ctl(`bg-accent-blue-400 text-purple-900`),
    },
  };

  return `${baseClasses} ${backgroundOpacityClasses[backgroundOpacity]} ${variantClasses[variant][theme]}`;
}
