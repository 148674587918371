import ctl from "@netlify/classnames-template-literals";

export const header = ctl(`
  relative
  px-4
  lg:px-24
`);

export const wrapper = ctl(`
  flex
  h-full
  w-full
  justify-center
`);

export const container = ctl(`
  relative
  w-full
  lg:max-w-5xl
`);

export const backArrow = ctl(`
  z-20
  py-3
  px-4
  flex
  gap-2
  text-white
`);

export const titlesContainer = ctl(`
  py-8
  w-full
`);
