import cx from "classnames";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSelect } from "downshift";
import { Transition } from "@headlessui/react";
import { UseFormRegister } from "react-hook-form";
import * as s from "../../shared/inputStyles";
import { CheckIcon, ChevronIcon } from "../../atoms/Icons";

export interface SelectItem {
  label: string;
  value: string | number;
  icon?: ReactNode;

  // select item can have any optional properties
  [key: string]: unknown;
}

export interface Props {
  disabled?: boolean;
  ellipsis?: boolean;
  hasError?: boolean;
  helperText?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  id: string;
  items: SelectItem[];
  label?: string;
  onChange?: (item: SelectItem) => void;
  placeholder?: string;
  size?: string;
  preSelectedItem?: SelectItem;
  required?: boolean;
  labelInfoIcon?: React.ReactElement;
  register?: UseFormRegister<any>;
}

export default function Select({
  disabled = false,
  ellipsis = false,
  hasError = false,
  helperText,
  iconLeft,
  iconRight,
  size,
  id,
  items,
  label,
  onChange,
  placeholder,
  preSelectedItem,
  required = false,
  labelInfoIcon,
  register,
}: Props) {
  const [selected, setSelected] = useState<SelectItem | null>(
    preSelectedItem ?? null,
  );
  const selectRef = useRef<HTMLSelectElement>(null);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items,
    itemToString: (item) => item?.label ?? "",
    selectedItem: selected,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }
      setSelected(selectedItem);
      onChange?.(selectedItem);
    },
  });

  useEffect(() => {
    register?.(id, { required });

    if (preSelectedItem) {
      onChange?.(preSelectedItem);
    }
  }, []);

  useEffect(() => {
    if (selectRef.current && selected) {
      selectRef.current.value = selected.value.toString();
    }
  }, [selected]);

  return (
    <div className={s.inputGroup}>
      <select
        id={id}
        name={id}
        ref={selectRef}
        className="hidden"
        defaultValue={preSelectedItem?.value}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>

      {label && (
        <label
          className={cx(s.label, {
            [s.labelDisabled]: disabled,
          })}
          htmlFor={id}
          {...getLabelProps()}
        >
          {label}
          {required && <span className={s.labelRequired}>*</span>}
          {labelInfoIcon ?? null}
        </label>
      )}
      <div className="relative">
        <button
          {...getToggleButtonProps()}
          type="button"
          className={cx(s.input, {
            "!px-3 !py-1": size === "small",
            "pl-12": Boolean(iconLeft),
            [s.inputError]: hasError,
            [s.selectButtonDisabled]: disabled,
            truncate: ellipsis,
          })}
        >
          {selected?.label ?? (
            <span className="text-neutral-500">{placeholder}</span>
          )}
          <ChevronIcon
            direction="down"
            className={cx({
              "-rotate-180 transform transition-all": isOpen,
              "rotate-0 transform transition-all": !isOpen,
            })}
          />
        </button>
        {iconLeft && (
          <span
            className={cx(s.icon, "left-4", {
              [s.iconError]: hasError,
              [s.iconDisabled]: disabled,
            })}
          >
            {iconLeft}
          </span>
        )}
        {iconRight && (
          <span
            className={cx(s.icon, "right-12", {
              [s.iconError]: hasError,
              [s.iconDisabled]: disabled,
            })}
          >
            {iconRight}
          </span>
        )}
      </div>
      <div className="relative z-10" {...getMenuProps()}>
        <Transition show={isOpen} {...s.transitionProps}>
          <ul className={cx(s.selectMenu)}>
            {items.map((item, index) => {
              const isSelected = selected?.value === item.value;
              return (
                <li
                  key={item.value}
                  {...getItemProps({ item, index })}
                  className={cx(s.selectMenuItem, {
                    "bg-neutral-50": isSelected,
                  })}
                >
                  {item.label}
                  {isSelected && <CheckIcon size={16} />}
                </li>
              );
            })}
          </ul>
        </Transition>
      </div>
      {helperText && (
        <span
          id="helperText"
          className={cx(s.helperText, {
            [s.helperTextDisabled]: disabled,
          })}
        >
          {helperText}
        </span>
      )}
    </div>
  );
}
