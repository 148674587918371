import { ChangeEvent, ReactElement } from "react";
import { UseFormRegister } from "react-hook-form";
import "./styles.css";
import cx from "classnames";
import tokens from "../../../theme/tokens";

export type CheckboxAlignment = "top" | "center" | "bottom";
export type CheckboxVariant = "default" | "white";

export interface Props {
  align?: CheckboxAlignment;
  name: string;
  label: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checked?: boolean;
  changeValueOnLabelClick?: boolean;
  width?: "full" | "auto";
  variant?: CheckboxVariant;
  register?: UseFormRegister<any>;
}

export default function Checkbox({
  align = "center",
  name,
  label,
  value,
  disabled = false,
  width = "full",
  checked,
  onChange,
  changeValueOnLabelClick = true,
  variant = "default",
  register,
}: Props): ReactElement {
  const isDefault = variant === "default";
  const labelClasses = getLabelClasses({ disabled, variant });

  return (
    <div
      className={cx("checkbox", {
        "w-full": width === "full",
        "w-auto": width === "auto",
        "!cursor-not-allowed": disabled,
        "items-center": align === "center",
        "items-start": align === "top",
      })}
    >
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        className={"checkbox__input w-6 h-6"}
        id={value}
        {...register?.(name)}
      />
      <div
        className={cx({
          "checkbox__svg-wrapper--default": isDefault,
          "checkbox__svg-wrapper--white": !isDefault,
          "mt-1": align === "top",
        })}
      >
        <InputCheck variant={variant} disabled={disabled} />
      </div>
      <label
        htmlFor={changeValueOnLabelClick ? value : undefined}
        className={labelClasses}
      >
        {label}
      </label>
    </div>
  );
}

function InputCheck({
  disabled,
  variant,
}: Pick<Props, "disabled" | "variant">): ReactElement {
  const color = getIconColor({ disabled, variant });

  return (
    <svg
      className="checkbox__svg"
      version="1.1"
      viewBox="0 0 17 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-9 -11)" fill={color} fillRule="nonzero">
          <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
        </g>
      </g>
    </svg>
  );
}

function getIconColor({
  disabled,
  variant,
}: Pick<Props, "disabled" | "variant">): string {
  if (disabled) {
    return tokens.colors["neutral"][500];
  }

  if (variant === "white") {
    return tokens.colors["neutral"][900];
  }

  return tokens.colors["purple"][500];
}

function getLabelClasses({
  disabled,
  variant,
}: Pick<Props, "disabled" | "variant">): string {
  const disabledClasses = "!cursor-not-allowed text-neutral-500";
  const textColor = variant === "white" ? "text-white" : "text-neutral-900";

  return cx("checkbox__label w-full", {
    [textColor]: !disabled,
    [disabledClasses]: disabled,
  });
}
