/* eslint-disable import/named */
import React, {
  Context,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser } from "@olivahealth/firebase/client";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import { NexusGenEnums } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";
import { AmplitudeClient, LogReturn } from "amplitude-js";

import usePreviousPage from "../../hooks/usePreviousPage";

export type EventNames =
  | "Landing page opened"
  | "Sign up link selected"
  | "Log in link selected"
  | "Service selected"
  | "Logged in"
  | "Login error occurred"
  | "Login attempted"
  | "Signed up"
  | "Session language changed"
  | "Session reason selected"
  | "Session booked"
  | "Session viewed"
  | "Cancellation attempt"
  | "Cancellation confirmed"
  | "Notes modified"
  | "Pre-session joined"
  | "Pre-session left"
  | "Matching survey finished"
  | "Match created"
  | "Therapy rematched"
  | "Reschedule attempt"
  | "Reschedule confirmed"
  | "Symptoms tracking survey started"
  | "Symptoms tracking survey section started"
  | "Symptoms tracking survey section finished"
  | "Symptoms tracking survey finished"
  | "Satisfaction survey started"
  | "Satisfaction survey finished"
  | "Session tags modified"
  | "Sent letter to professional"
  | "Answered letter sent to professional"
  | "Response viewed"
  | "Client viewed"
  | "Emergency contact displayed"
  | "Clinical questionnaire opened"
  | "Clinical background displayed"
  | "Personal info displayed"
  | "Self-care entry clicked"
  | "Self-care questionnaire finished"
  | "Workshops navigated"
  | "Workshops filtered"
  | "Workshop waiting list joined"
  | "Workshops displayed"
  | "User management page viewed"
  | "Invite creation navigated"
  | "Invite creation error occurred"
  | "Invite sent"
  | "Invite resent"
  | "Invite revoked"
  | "Invite viewed"
  | "Admin added"
  | "Admin removed"
  | "Session payment awaiting"
  | "Account deactivated"
  | "Account reactivated"
  | "Account email modified"
  | "Invite accepted"
  | "Restricted service viewed"
  | "Workshop colleague invited"
  | "Library navigated"
  | "Library viewed"
  | "Resource viewed"
  | "Resource shared"
  | "Resource URL copied"
  | "Resource downloaded"
  | "Resource played"
  | "Resource previewed"
  | "Resource recommendation viewed"
  | "Profile modified"
  | "Privacy commitment viewed"
  | "Privacy notice navigated"
  | "Onboarding finished"
  | "Triaging started"
  | "Triaging section started"
  | "Triaging section finished"
  | "Triaging question answered"
  | "Triaging finished"
  | "Matching survey section finished"
  | "Matching survey section started"
  | "Care type playback started"
  | "Care type playback finished"
  | "Switched to personal plan"
  | "Wellbeing profile viewed"
  | "Self-help centre viewed"
  | "Matching survey started"
  | "User joined session"
  | "User left session"
  | "Support widget navigated"
  | "Engagement report viewed"
  | "Matching survey question answered"
  | "Homepage viewed"
  | "Session language unavailable"
  // Self Guided
  | "Content list viewed"
  | "Content details viewed"
  | "Content playback started"
  | "Content playback finished"
  | "Content playing"
  | "Resource favourites list viewed"
  | "Content group viewed"
  | "Content added to favourites"
  | "Content removed from favourites"
  // 1-1 page events
  | "1-to-1 page viewed"
  | "Session list viewed"
  | "FAQ page navigated"
  | "FAQ question extended"
  | "Match list proposed"
  | "Match selected"
  | "MHP profile viewed"
  | "Wellbeing profile resetted"
  // Organisation Resources events
  | "Assets list viewed"
  | "Assets group viewed"
  | "Asset details viewed"
  | "Asset downloaded"
  | "Asset content copied"
  // Crisis Support
  | "Crisis support viewed"
  | "Crisis support call clicked"
  | "Crisis support sms clicked"
  | "Crisis support online chat clicked"
  | "Crisis support whatsapp clicked"
  | "Crisis support emergency services clicked"
  | "Crisis support helpline website clicked"
  // Employee unsatisfied with professional survey
  | "Rematching survey started"
  | "Rematching survey question answered"
  | "Rematching survey finished"
  // NPS Survey
  | "NPS survey viewed"
  | "NPS survey started"
  | "NPS survey finished"
  | "NPS survey dismissed"
  // Chat
  | "Chat entered"
  | "Chat exited";

export type ErrorEventNames =
  | "Failed to fetch"
  | "Unexpected error"
  | "Validation error";

export enum ExternalResourceType {
  file = "file",
  URL = "URL",
}

export function guessWorkshopEventType(
  workshop?: { type: WorkshopType } | null,
): string {
  if (workshop?.type === WorkshopType.TALK) {
    return "TALK";
  }

  return "WORKSHOP";
}

export interface TrackEvent {
  (name: EventNames, eventProperties?: Record<string, any>): LogReturn;
}

interface TrackEventForUserId {
  (
    name: EventNames,
    userId: string,
    eventProperties?: Record<string, any>,
  ): LogReturn;
}

interface IdentifyAmplitudeUser {
  (organisation: string, roles: NexusGenEnums["UserRoleEnum"][]): Promise<void>;
}

interface IAmplitudeContext {
  trackEvent: TrackEvent;
  trackEventForUserId: TrackEventForUserId;
  identifyAmplitudeUser: IdentifyAmplitudeUser;
  identifySetField: (field: string, value: any) => Promise<void>;
  previousPage: string | null;
  clearStorage: () => void;
}

const defaultContext: IAmplitudeContext = {
  trackEvent: () => 400,
  trackEventForUserId: () => 400,
  identifyAmplitudeUser: () => Promise.resolve(),
  identifySetField: () => Promise.resolve(),
  previousPage: null,
  clearStorage: () => null,
};

const AmplitudeContext: Context<IAmplitudeContext> =
  createContext(defaultContext);
export const useAmplitude = (): IAmplitudeContext =>
  useContext(AmplitudeContext);

export default function AmplitudeContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { data: user } = useUser();
  const [client, setClient] = useState<AmplitudeClient | null>(null);
  const { previousPage, currentPage } = usePreviousPage();

  useEffect(() => {
    if (typeof window !== "undefined") {
      import("amplitude-js").then((amplitude) => {
        const client = amplitude.default.getInstance();
        client.init(process.env.NEXT_PUBLIC_AMPLITUDE_SDK_KEY ?? "");

        setClient(client);
      });
    }
  }, []);

  useEffect(() => {
    if (!user || !client) {
      return;
    }
    client.setUserId(user.uid);
  }, [user, client]);

  const trackEvent: TrackEvent = useCallback(
    (eventName, eventProperties) => {
      return client?.logEvent(eventName, {
        ...eventProperties,
        currentPage,
        previousPage,
      });
    },
    [client, currentPage, previousPage],
  );

  const trackEventForUserId: TrackEventForUserId = (
    eventName,
    userId,
    eventProperties,
  ) => {
    client?.setUserId(userId);
    const eventLogResponse = client?.logEvent(eventName, eventProperties);
    client?.setUserId(user?.uid ?? "");
    return eventLogResponse;
  };

  const identifyAmplitudeUser = (organisation, roles) => {
    return new Promise<void>((resolve) => {
      if (typeof window !== "undefined") {
        import("amplitude-js").then((Amplitude) => {
          const identityObject = new Amplitude.default.Identify()
            .set("organisation", organisation ? organisation : "UNKNOWN")
            .set("roles", roles);
          client?.identify(identityObject, () => {
            resolve();
          });
        });
      }
      resolve();
    });
  };

  const identifySetField = (field, value) => {
    return new Promise<void>((resolve) => {
      if (typeof window !== "undefined") {
        import("amplitude-js").then((Amplitude) => {
          const identityObject = new Amplitude.default.Identify().set(
            field,
            value,
          );
          client?.identify(identityObject, () => {
            resolve();
          });
        });
      }
      resolve();
    });
  };

  const clearStorage = () => {
    client?.clearStorage();
  };

  return (
    <AmplitudeContext.Provider
      value={{
        trackEvent,
        trackEventForUserId,
        identifyAmplitudeUser,
        identifySetField,
        previousPage,
        clearStorage,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
}
