import ctl from "@netlify/classnames-template-literals";

export const tabListWrapper = ctl(`
  mb-8 
  after:mt-[-2px] 
  after:block 
  after:border-b-2
  z-10
`);

export const tabWrapper = ctl(`
  mr-4
  md:mr-8
  last:mr-0
  flex
`);
