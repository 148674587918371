import ctl from "@netlify/classnames-template-literals";

export const getWrapperStyles = (enabled: boolean) =>
  ctl(`
    ${enabled ? "bg-green-300" : "bg-neutral-200"} 
    relative 
    inline-flex 
    items-center 
    h-9
    w-16
    rounded-full
    transition-colors 
    focus:outline-none
  `);

export const getInnerStyles = (enabled: boolean) =>
  ctl(
    `${enabled ? "translate-x-7" : "translate-x-1"} 
    bg-white
    inline-block 
    w-8
    h-8
    transform 
    rounded-full 
    transition-transform
  `,
  );
