import Link from "next/link";
import { ReactElement } from "react";
import CareType from "@olivahealth/graphql-server/src/domain/value-objects/CareType";
import Button from "../../atoms/Button";
import Card from "../../atoms/Card";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import EventCardV2 from "./EventCardV2";

export interface EventCardProfessionalData {
  image: string | null;
  name: string;
  title: string | null;
}

export type EventCardType =
  | "check-in"
  | "class"
  | "teamSession"
  | "therapy"
  | "workshop";

interface Props {
  applyBackgroundMask?: boolean;
  awaitingPayment?: string | null;
  buttonLabel?: string;
  careType: CareType | null;
  date: string;
  duration?: string;
  eventTypeTranslation?: string;
  id: string;
  image?: ReactElement;
  imageSource?: string;
  isActive?: string;
  onClickButton?: () => void;
  pendingProgressCheckSurvey: string | null;
  professional?: EventCardProfessionalData;
  refunded?: string;
  showButton?: boolean;
  title?: string;
  type: EventCardType;
  url: string;
  variant?: "v1" | "v2";
}

export default function EventCard({
  applyBackgroundMask,
  awaitingPayment,
  buttonLabel,
  careType,
  date,
  duration,
  eventTypeTranslation,
  id,
  image,
  imageSource,
  isActive,
  onClickButton,
  pendingProgressCheckSurvey,
  professional,
  refunded,
  showButton = false,
  title,
  type,
  url,
  variant = "v1",
}: Readonly<Props>): ReactElement {
  const isClass = type === "class";

  return (
    <Link href={url} data-testid={`card-session-${id}`}>
      {variant === "v2" ? (
        <EventCardV2
          applyBackgroundMask={applyBackgroundMask}
          awaitingPayment={awaitingPayment}
          buttonLabel={buttonLabel as string}
          careType={careType}
          date={date}
          duration={duration}
          eventTypeTranslation={eventTypeTranslation}
          id={id}
          imageSource={imageSource}
          isActive={isActive}
          onClickButton={onClickButton}
          pendingProgressCheckSurvey={pendingProgressCheckSurvey}
          professional={professional}
          refunded={refunded}
          showEventTypeAsTag={type === "teamSession" || type === "therapy"}
          title={title}
        />
      ) : (
        <Card animateOnHover>
          <div className={"cursor-pointer"}>
            <div className="relative h-24 w-full object-cover lg:h-36">
              {image}
            </div>
            <div className="flex flex-col gap-2 p-4">
              <div className="flex gap-2">
                {eventTypeTranslation && <Tag>{eventTypeTranslation}</Tag>}
                {duration && <Tag>{duration}</Tag>}
                {awaitingPayment && (
                  <Tag variant="negative">{awaitingPayment}</Tag>
                )}
              </div>
              <div className="h-12">
                {isClass ? (
                  <Text>{title}</Text>
                ) : (
                  <div className="flex">
                    {professional?.image && (
                      <div className="mr-4 h-12 w-12">
                        <img
                          src={professional.image}
                          alt={professional.name}
                          className="rounded-full"
                        />
                      </div>
                    )}
                    <div className="flex flex-col">
                      <Text weight="bold">{professional?.name}</Text>
                      <Text color="secondary">{professional?.title}</Text>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex h-10 items-center justify-between">
                <Text>{date}</Text>
                {showButton && buttonLabel && (
                  <Button size="sm" onClick={onClickButton}>
                    {buttonLabel}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}
    </Link>
  );
}
