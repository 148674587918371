import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  RRNLRequestError,
} from "react-relay-network-modern";
import {
  Environment,
  RecordSource,
  Store,
  QueryResponseCache,
  Observable,
} from "relay-runtime";
import { getApp } from "@firebase/app";
import { getAuth } from "@firebase/auth";
import logger from "@olivahealth/logger/client";
import { IMPERSONATION_LOCAL_STORAGE_KEY } from "@olivahealth/constants";
import { createClient } from "graphql-ws";

const source = new RecordSource();
const store = new Store(source);

/**
 * WARNING!
 *
 * Clearing the store source actually makes all usages of data fetching or mutations
 * to lose reference of the data, this should not be called unless you know exactly what
 * you are doing and checking for any side effects.
 * -> useLazyLoadQuery, useFragment, loadQuery, useMutation etc.
 *
 * If the component that expects the data be there remains mounted calling this
 * method makes the data return null and fails to re-fetch the data.
 *
 * Mutations are triggered with empty values when this is called and the component
 * remains mounted, potentially causing side effects on the server.
 *
 * Only call this method when it makes sense to invalidate the store and the best
 * practice could be refreshing/redirecting the user.
 */
export function dangerouslyClearStoreSource() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "local") {
    console.warn("Invalidate store was called!");
  }
  source.clear();
}

let storeEnvironment: Environment | null = null;
let refreshToken = false;

export function getGraphqlUrl(): string {
  const url = process.env.NEXT_PUBLIC_VERCEL_URL ?? "";

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    return "https://olivahealth.app";
  }

  if (!url) {
    return "http://localhost:3005";
  }

  return url.match(/https?:\/\/.+?(?=\/|$)/)?.[0] ?? "";
}

export function getWebsocketUrl(): string {
  const url = process.env.NEXT_PUBLIC_BASE_URL;

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
    return "wss://olivahealth.app";
  }

  if (!url) {
    return "ws://localhost:3005";
  }

  // Replace for staging and preview environments
  return `wss://${url.replace(/https?/, "")}`;
}

function getCacheKey(req) {
  return `${req.getID()}${req?.fetchOpts.headers.Authorization}`;
}

export default function cacheMiddleware() {
  const cache = new QueryResponseCache({
    size: 100, // 100 requests
    ttl: 15 * 60 * 1000, // 15 minutes
  });

  return (next) => async (req) => {
    try {
      const variables = req.getVariables();
      const cacheKey = getCacheKey(req);

      if (req.cacheConfig && req.cacheConfig.force) {
        const res = await next(req);

        if (!res.errors) {
          cache.set(cacheKey, variables, res);
        }

        return res;
      }

      const cachedRes = cache.get(cacheKey, variables);
      const res = await next(req);

      // If there are errors on this new request, bypass the cache
      if (res.errors) {
        return res;
      }

      if (cachedRes) {
        return cachedRes;
      }

      cache.set(cacheKey, variables, res);
      return res;
    } catch (error) {
      if (error instanceof Error && error.message.includes("Failed to fetch")) {
        console.error("Failed to connect to Graphql server", error);
        logger.error("Relay request", "Failed to connect to Graphql server", {
          error,
        });
      } else {
        console.error(error);
      }
      return next(req);
    }
  };
}

// This middleware is used to log when a user has been rate limited
export function rateLimitingMiddleware() {
  return (next) => async (req) => {
    try {
      const res = await next(req);

      return res;
    } catch (error) {
      if (error instanceof RRNLRequestError) {
        const statusCode = error.res?.status;
        if (statusCode === 429) {
          const app = getApp();
          logger.error(
            "Rate Limit",
            "User has been rate limited on the application",
            {
              userId: getAuth(app).currentUser?.uid,
            },
          );
        }
      }

      return next(req);
    }
  };
}

export function refreshAuthToken(): void {
  refreshToken = true;
  storeEnvironment = null;
  createEnvironment();
}

export function createEnvironment(): Environment {
  if (storeEnvironment) return storeEnvironment;

  const wsClient = createClient({
    url: `${getWebsocketUrl()}/api/subscriptions`,
    connectionParams: async () => {
      const app = getApp();
      const currentUser = await getAuth(app).currentUser;
      const token = (await currentUser?.getIdToken(refreshToken)) ?? "";
      refreshToken = false;
      if (!token) {
        return {};
      }
      let impersonatorId = "";
      const storage = localStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY);
      if (storage) {
        const { impersonatorId: storageImpersonatorId } = JSON.parse(storage);
        if (storageImpersonatorId) {
          impersonatorId = storageImpersonatorId;
        }
      }
      return {
        Authorization: `Bearer ${token}`,
        "x-audit-impersonator-id": impersonatorId,
      };
    },
  });

  storeEnvironment = new Environment({
    store,
    network: new RelayNetworkLayer(
      [
        urlMiddleware({
          url: () => `${getGraphqlUrl()}/api/graphql`,
          headers: () => {
            const storage = localStorage.getItem(
              IMPERSONATION_LOCAL_STORAGE_KEY,
            );
            const defaultHeaders = {
              "x-audit-impersonator-id": "",
            };
            if (!storage) {
              return defaultHeaders;
            }
            const { impersonatorId } = JSON.parse(storage);
            if (!impersonatorId) {
              return defaultHeaders;
            }
            return {
              ...defaultHeaders,
              "x-audit-impersonator-id": impersonatorId as string,
            };
          },
        }),
        authMiddleware({
          token: async () => {
            const app = getApp();
            const currentUser = await getAuth(app).currentUser;
            const token = currentUser?.getIdToken(refreshToken) ?? "";
            refreshToken = false;
            return token;
          },

          allowEmptyToken: true,
        }),
        rateLimitingMiddleware(),
        cacheMiddleware(),
      ],
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        subscribeFn: (operation, variables): Observable<unknown> => {
          return Observable.create((sink) => {
            if (!operation.text) {
              return sink.error(new Error("Operation text cannot be empty"));
            }
            return wsClient.subscribe(
              {
                operationName: operation.name,
                query: operation.text,
                variables,
              },
              sink,
            );
          });
        },
      },
    ),
  });

  return storeEnvironment;
}

export function getEnvironment(): Environment | null {
  return storeEnvironment;
}
